import React from "react";
import Nav from "./navbar";
import Footer from "./footer";

const Project = ()=>{
    return(
        <div className="min-h-screen bg-bgblack">
        <Nav />
        <div className="h-screen">

        </div>
        <Footer />
        </div>
    )
}

export default Project;